<form [formGroup]="dataForm">

  <mat-form-field class="forms">
    <mat-label><mat-icon>language</mat-icon> {{ 'menu.languageSelect' | translate }}</mat-label>
    <mat-select formControlName="language">
      @for (item of languages; track item) {
      <mat-option [value]="item.value">
        {{ item.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="forms">
    <mat-label><mat-icon>format_color_fill</mat-icon> {{ 'menu.themeSelect' | translate }}</mat-label>
    <mat-select formControlName="theme">
      @for (theme of themes; track theme) {
      <mat-option [value]="theme">
        {{ theme | translate }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="forms">
    <mat-label><mat-icon>pin</mat-icon> {{ 'menu.separatorSelect' | translate }}</mat-label>
    <mat-select formControlName="separator">
      @for (item of dataSeparators; track item) {
      <mat-option [value]="item.value">
        {{ item.name | translate}}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
</form>