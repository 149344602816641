import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DEFAULT_SCHEME, NotificationService } from '../../../../services/notification.service';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
  ]
})
export class AccountComponent {
  private readonly translate = inject(TranslateService);
  private readonly notificationService = inject(NotificationService);
  public dataForm: FormGroup;

  public dataSeparators = [
    { name: 'comma', value: ',' },
    { name: 'semicolon', value: ';' },
  ]

  public themes = [
    DEFAULT_SCHEME,
    'light',
    'dark',
  ];

  public languages = [
    { name: 'English 🇬🇧', value: 'en-US' },
    { name: 'Polski 🇵🇱', value: 'pl-PL' },
  ];

  public offlineAccess = false;
  constructor() {
    this.dataForm = new FormGroup({
      language: new FormControl(this.translate.currentLang),
      theme: new FormControl(this.notificationService.getDefaultTheme()),
      separator: new FormControl(localStorage.getItem('separator') || this.dataSeparators[0].value),
    });

    this.offlineAccess = JSON.parse(localStorage.getItem('offlineAccess') || 'false');

    this.dataForm.get('separator')?.valueChanges.subscribe(
      data => localStorage.setItem('separator', data)
    )

    this.dataForm.get('language')?.valueChanges.subscribe(
      lang => {
        localStorage.setItem('language', lang);
        this.translate.use(lang)
      }
    )

    this.dataForm.get('theme')?.valueChanges.subscribe(
      (theme: string) => this.notificationService.setTheme(theme)
    )
  }


}
